@charset "utf-8";

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/work-sans-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/work-sans-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/work-sans-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/work-sans-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/work-sans-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/work-sans-v7-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}

// Define defaults for each variable.

$base-font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #212529 !default;
$background-color: #f0f0f8 !default;
$brand-color:      #E31337 !default;

$grey-color:       #212529 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;

.site-header {
  background-image: url('images/hextacular.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 55.95px;
  background-color: #e7e7f1;
  border-top: 5px solid black;
  border-bottom: 1px solid #7e8b98;
  min-height: 55.95px;
  position: relative;
  padding-left: 55.95px;
}

.site-footer {
  background-color: #e7e7f1;
  border-top: 1px solid #7e8b98;
  padding: 30px 0;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border: 1px solid #212529;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 4px 4px rgba(0,0,0,.05);
  box-shadow: inset 0 4px 4px rgba(0,0,0,.05);
}

// See: https://github.com/turbolinks/turbolinks#displaying-progress
.turbolinks-progress-bar {
  height: 5px;
  background-color: #E31337;
}

.form-inline {
  display: inline;
  align-items: center;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pull-right {
  float: right;
  padding-left: 1rem;
  max-width: 50%;
}

#results-container {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 27.975px;
  background-color: #e7e7f1;
  position: fixed;
  top: 40;
  z-index: 100;
  min-height: 20px;
  border-radius: 6px;
  -webkit-box-shadow: 2px 2px 10px grey;
  box-shadow: 2px 2px 10px grey;
}

#results-container li {
  padding-left: 10px;
  padding-right: 10px;
  list-style-type: none;
}

.list-style-avatar {
  margin: 0;
  padding: 0px 0 4px 52px;
  list-style: none;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 48px;
}

.post-thumbnail img {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px;  /* Rounded border */
  padding: 5px; /* Some padding */
  width: 150px; /* Set a small width */
}

/* Add a hover effect (blue shadow) */
.post-thumbnail img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.lazyload {
	opacity: 0;
}

.lazyloading {
	opacity: 1;
	transition: opacity 300ms;
	background: #f7f7f7 url(images/grey-gear-loading.gif) no-repeat center;
}
